import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Shaan is a charming restaurant located in Worthing, a seaside town on
        the south coast of England. As you step inside the restaurant, you'll be
        greeted with an ambiance that's both modern and cosy, featuring elegant
        decor with a warm and inviting atmosphere.
      </Typography>
      <Typography variant="body1" paragraph>
        The menu at Shaan offers a range of authentic Indian cuisine, expertly
        prepared by skilled chefs using the freshest and finest ingredients.
        From aromatic curries to spicy tandoori dishes, each dish is
        thoughtfully crafted to deliver a unique and satisfying dining
        experience. Vegetarian and non-vegetarian options are available,
        catering to a diverse range of preferences and tastes.
      </Typography>
      <Typography variant="body1" paragraph>
        The restaurant also offers exceptional service, with attentive staff who
        are always ready to assist and ensure your dining experience is
        enjoyable and memorable. Whether you're looking for a romantic dinner
        for two or a night out with friends and family, Shaan is the perfect
        destination for a truly exceptional dining experience in Worthing.
      </Typography>
      <Typography variant="body1" paragraph>
        With its delicious food, friendly service, and inviting ambiance, Shaan
        is a must-visit restaurant for anyone looking for an authentic taste of
        India in Worthing.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
